@import "mixins"
.header
    @include accountStickyHeader
    margin-bottom: 0

    > div:first-of-type
        max-width: calc(100% - 128px)
        overflow: hidden

        > span
            text-transform: initial
            @include bold
            color: #fff

    .dropdown
        width: 120px
        margin-right: 8px
        select
            background: var(--ddl_buttonBg)
            box-shadow: inset 0 1px var(--ddl_buttonShadow)

.back
    @include backArrowBtn
    min-width: 14px

.reportTabs
    composes: myBetsTabs from "components/layout/bets/account-bets-layout.module.sass"
    box-shadow: 0 1px 0 rgba(255,255,255, 0.1)
    border-radius: var(--base_borderRadius)

    a
        height: 36px

    .active
        background: var(--account_agentTabActiveBg)
        pointer-events: none
        svg
            path
                fill: var(--account_agentTabActiveIconFill) !important
