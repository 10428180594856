@import "mixins"
.myBets
  padding: 0 8px
  flex: 1
  display: flex
  flex-direction: column

.header
  @include accountStickyHeader

.back
  @include backArrowBtn

.myBetsTabs
  @include flex

  a,
  > span
    width: 50px
    height: 32px
    background: var(--account_tabsBg)
    @include flex
    justify-content: center
    :global(.svg-icon)
      svg
        min-width: 15px
        width: auto
        height: 16px
        path
          fill: rgb(var(--primaryColor))
    &:not(:last-of-type)
      margin-right: 1px
    &:first-of-type
      border-top-left-radius: var(--base_borderRadius)
      border-bottom-left-radius: var(--base_borderRadius)
    &:last-of-type
      border-top-right-radius: var(--base_borderRadius)
      border-bottom-right-radius: var(--base_borderRadius)

  :global(.myBetsTabActive)
    background: var(--account_tabActiveBg)
    pointer-events: none
    svg
      path
        fill: var(--button_color) !important

@media screen and (max-width: 380px) and (orientation: portrait)
  .myBetsTabs
    a,
    > span
      width: 40px
