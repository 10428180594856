@import "mixins"
.agentReportPage
  position: relative
  padding: 0 8px
  flex: 1
  display: flex
  flex-direction: column

  :global(.no-data)
    position: absolute
    left: 50%
    transform: translateX(-50%)

.tools
  @include flex
  justify-content: space-between
  height: 54px
  min-height: 54px

  .dropdown
    flex: 1
    max-width: 250px

    select
      background: var(--ddl_buttonBg)
      box-shadow: inset 0 1px var(--ddl_buttonShadow)

    &:first-of-type
      margin-right: 10px

.agentReportTabs
  composes: myBetsTabs from "../../../layout/bets/account-bets-layout.module.sass"
  margin-left: auto

  > a
    height: 36px

  .casinoSlot
    margin-right: 1px

  .agentReportTabActive
    background: var(--account_agentReportTabActiveBg)
    svg
      path
        fill: var(--button_color) !important

.reportDetails
  @include flex
  background: var(--account_agentReportDetailsBg)
  border-radius: 2px
  > div
    width: 100%
    flex: 1
    &:not(:nth-child(1))
      border-left: 1px solid var(--body_bg)

    &:last-of-type > div:nth-child(1) > i
      border-top-right-radius: 2px

    > div:nth-child(1)
      width: 100%
      height: 44px
      display: flex
      flex-direction: column
      justify-content: center
      padding: 0 6px
      @include bold

      > i
        position: absolute
        right: 0
        top: 0
        background: var(--account_agentReportDetailsInfoBg)
        box-shadow: 0 1px 8px rgba(0, 0, 0, 0.2)
        border-bottom-left-radius: 3px
        color: var(--account_agentReportDetailsInfoColor)
        width: 16px
        height: 16px
        padding-bottom: 3px
        font-style: unset
        @include flex
        justify-content: center
        font-size: 14px
        font-weight: unset

      > span:last-of-type
        color: #fff
        margin-top: 6px

        strong
          color: var(--account_agentReportDetailsPercentColor)
          margin-left: 4px
    > div:nth-child(2)
      top: calc(100% + 6px)
      background: var(--account_agentReportDetailsTooltipBg)
      border-radius: 0 0 4px 4px
      border-top: 3px solid var(--account_agentReportDetailsTooltipBorder)
      min-width: 134px
      padding: 0 6px
      bottom: unset

      &:before
        content: ""
        width: 0
        height: 0
        border-style: solid
        border-width: 0 5px 6px 5px
        border-color: transparent transparent var(--account_agentReportDetailsTooltipBorder) transparent
        position: absolute
        top: -8px
        margin: 0
        bottom: unset
        transform: translateX(-50%)

      > div
        @include flex
        height: 32px
        color: #fff

        :global(.svg-icon)
          margin-right: 10px

        &:not(:first-child)
          border-top: 1px dotted #7e8696

.list
  flex: 1
  position: relative
  padding-bottom: var(--menuHeight)

.listItem
  background: var(--account_agentReportListItemBg)
  border: 1px solid var(--account_betsItemBorder)
  border-radius: 3px
  overflow: hidden
  margin-bottom: 6px
  font-size: 13px
  line-height: 13px
  &:first-of-type
    margin-top: 6px

.listItemHead
  background: var(--account_agentReportListItemHeaderBg)
  @include flex
  justify-content: space-between
  color: #fff
  @include bold
  padding: 0 8px
  height: 40px

  > *
    display: flex
    flex-direction: column

    span:first-of-type
      margin-bottom: 6px

  > span
    text-align: right

    > span:first-of-type
      color: rgb(var(--primaryColor))
      font-weight: normal

.bonusRow
  @include flex

  > div
    display: flex
    flex-direction: column
    height: 46px
    flex: 1
    justify-content: center
    padding: 0 8px

    &:first-of-type
      border-right: 1px solid var(--account_agentReportListItemHeaderBg)

    > span:last-of-type
      color: #fff
      margin-top: 6px
      @include bold

      strong
        color: rgb(var(--thirdaryColor))
        margin-left: 4px
