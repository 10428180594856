@import "mixins"
.agentCreatePlayerPage
  composes: agentReportPage from "../report/agent-report.module.sass"
  padding-bottom: var(--menuHeight)
  --field_bg: var(--account_agentFieldBg)

  div:has(:global(.field-error))
    > input, :global(.intl-tel-input) > input
      box-shadow: inset 0 0 0 1px rgb(var(--particularColor2))

.header
  @include accountStickyHeader

  > div:first-of-type
    max-width: calc(100% - 128px)
    overflow: hidden

    > span strong
      text-transform: initial
      margin-left: 4px
      @include ellipsis
  > span strong
    color: #fff
    margin-left: 4px

.back
  @include backArrowBtn
  min-width: 14px

.field
  width: 100%
  margin-bottom: 10px
  position: relative

  :global(.viewPass)
    max-height: 36px

.text
  margin-top: 4px

.name
  @include flex
  > div
    flex: 1
    &:first-of-type
      margin-right: 6px

.agentCode
  background: var(--account_agentFieldCodeBg)
  border: 0
  border-radius: var(--base_borderRadius)
  padding: 0 10px
  color: #fff
  height: 36px
  @include flex
  text-transform: uppercase
  @include bold

.cashbacks
  border-top: 1px solid var(--account_borderLight)
  border-bottom: 1px solid var(--account_borderDark)
  box-shadow: 0 1px var(--account_borderLight), 0 -1px var(--account_borderDark)
  padding: 2px 0 10px
  margin: 10px 0
  > div
    display: flex
    align-items: baseline
    justify-content: space-between
    color: #fff
    font-size: 14px
    margin-top: 8px
  .field
    display: flex
    align-items: baseline
    width: auto
    margin-bottom: 0
    > label
      min-width: 88px
    input
      width: 100px

.formButtons
  @include flex
  margin-bottom: 10px
  > button
    flex: 1
    &:nth-child(2)
      margin-left: 8px
