@import "mixins"
.numberBoxContainer
  overflow: hidden
  flex: 1 100%
  height: 0

.customNumberBox
  width: 100%
  padding: 2px
  display: grid
  grid-template-columns: repeat(8, 1fr)
  gap: 2px
  > div
    text-align: center
    color: #fff
    background: var(--numberBox_itemBg)
    border: 1px solid #000
    box-shadow: 0 1px rgba(255,255,255, 0.1)
    text-shadow: 0 -1px rgba(0, 0, 0, 0.7)
    font-size: 14px
    min-height: 42px
    @include flex
    justify-content: center
    border-radius: 2px

    &:not(.disable):active
      background: var(--numberBox_itemActiveBg)

    &.saveBtn
      padding: 0 2px
      font-weight: bold
      background: var(--numberBox_saveBtnBg)
      color: var(--numberBox_saveBtnColor)
      border: var(--numberBox_saveBtnBorder)
      text-shadow: none
      grid-column-start: 8
      grid-column-end: 9
      grid-row-start: 1
      grid-row-end: 3

      &:not(.disabled):active
        background: var(--numberBox_saveBtnActiveBg)

    &.closeNumberBox
      background: var(--numberBox_closeBtnBg)
      grid-column-start: 6
      grid-column-end: 8

    &.disabled
      opacity: 0.5
      pointer-events: none

    :global(.svg-icon)
      justify-content: center
