@import "mixins"
.agentPlayersPage
  composes: agentReportPage from "../report/agent-report.module.sass"

.list
  composes: list from "../report/agent-report.module.sass"

.playerItem
  background: var(--account_agentPlayerItemBg)
  overflow: hidden
  margin-top: 8px
  font-size: 13px
  line-height: 13px
  > div:last-of-type
    border-bottom-left-radius: 3px
    border-bottom-right-radius: 3px

.playerItemHeader
  background: var(--account_agentPlayerItemHeaderBg)
  border: 1px solid var(--account_betsItemBorder)
  border-top-left-radius: 3px
  border-top-right-radius: 3px
  @include flex
  height: 40px
  color: #fff

  > span:first-of-type
    min-width: 58px
    width: 58px
    text-align: center
    border-right: 1px dotted var(--sport_separator)
    margin-right: 10px
    line-height: 15px

    > span
      display: block
      margin-bottom: 4px

  > div
    @include bold
    line-height: 16px

    > span
      display: block
      margin-top: 2px
      @include ellipsis
      line-height: 16px

  .button
    min-width: 36px
    width: 36px
    height: 36px
    border-radius: 100%
    background: var(--account_agentPlayerItemSettingsBtnBg)
    @include flex
    justify-content: center
    margin: 0 6px 0 auto

.sportPercents
  @include flex
  @include bold
  text-transform: uppercase
  color: #fff
  border: 1px solid var(--account_betsItemBorder)
  margin-top: -1px
  > div
    @include flex
    height: 35px
    padding: 0 6px
    min-width: 110px

    strong
      color: rgb(var(--thirdaryColor))
      margin-left: 10px

    &:nth-child(2)
      background: var(--account_agentPlayerItemLiveCasinoBg)
      border-right: 1px solid var(--account_betsItemBorder)
      border-left: 1px solid var(--account_betsItemBorder)
      flex: 1

.sportPercentsEditableRow
  @include flex
  flex-wrap: wrap
  position: relative
  border: 1px solid var(--account_betsItemBorder)
  margin-top: -1px
  &:has(.editable)
    border-color: var(--account_agentEditableFieldBorder) !important
    z-index: 1

  &:not(:last-of-type)
    border-bottom: 1px solid var(--account_betsItemBorder)

  > span:first-of-type
    min-width: 64px
    width: 64px
    height: 51px
    padding-left: 7px
    background: var(--account_agentPlayerItemCategoryBg)
    text-transform: uppercase
    color: #fff
    line-height: 16px
    @include flex
    @include bold

  .field
    @include flex

    label
      margin: 0 8px
      text-transform: capitalize
      font-size: 13px

    input
      border: 1px solid rgba(0, 0, 0, 0.7)
      width: 130px
      transition: all 0.15s

      + span
        position: absolute
        bottom: calc(100% + 8px)
        left: 2px
        border-radius: 3px
        width: unset
        padding: 5px 8px 4px
        background: #fff
        margin: 0
        box-shadow: 0 0 8px rgba(#000, 0.7)
        overflow: unset

        &:before
          content: ""
          width: 0
          height: 0
          border-style: solid
          border-width: 6px 3px 0 3px
          border-color: #fff transparent transparent transparent
          position: absolute
          bottom: -6px

    &.editable input
      background: #fff
      border-color: #000
      color: var(--txt_color)

.agentPercent
  @include flex
  flex-direction: column
  justify-content: center
  position: absolute
  top: 0
  height: 51px
  right: 8px

  strong
    color: rgb(var(--thirdaryColor))
    margin-top: 4px
    @include ellipsis
    max-width: 100px
